import React from 'react'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { LOGIN_RESTORED } from '../ducks/auth'
import Toasts from './Toasts'
import layoutStyles from '../styles/Layout.module.scss'
import Nav from '../containers/Nav'

const mapStateToProps = state => {
  return {
    currentUser: state.auth.currentUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    restoreSession: ({ id, token }) => dispatch(LOGIN_RESTORED({ id, token }))
  }
}

class Layout extends React.Component {
  constructor (props) {
    super(props)
    const cachedUser = localStorage.getItem('currentUser')
    if (cachedUser) {
      this.props.restoreSession(JSON.parse(cachedUser))
    }
  }

  render () {
    const classes = classnames(
      layoutStyles.content,
      { [layoutStyles.authenticated]: this.props.currentUser }
    )
    return (
      <div>
        <Toasts messages={[{ title: 'Logged in', status: 'success' }, { title: 'Something went wrong', status: 'error', text: 'Please try again later' }]} />
        <div className={layoutStyles.root}>
          {this.props.currentUser && <Nav />}
          <div className={classes}>
            <img src="/logo_color.png" className={layoutStyles.mobileLogo} alt="logo" />
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
