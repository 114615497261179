import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import keyBy from 'lodash/keyBy'
import groupBy from 'lodash/groupBy'
import find from 'lodash/find'
import remove from 'lodash/remove'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'customer',
  initialState: {
    loading: true,
    address: '',
    postcode: '',
    tel: '',
    selectedPlan: null,
    selectedExtras: [],
    selectedSubscription: null,
    paymentsBySubscription: {},
    payments: [],
    nextPayment: null,
    subscriptionsById: {}
  },
  reducers: {
    SELECT_PLAN (state, { payload }) {
      state.selectedPlan = payload
    },
    SELECT_EXTRA (state, { payload }) {
      const exists = find(state.selectedExtras, { _id: payload._id })
      if (exists) {
        remove(state.selectedExtras, n => n._id === payload._id)
      } else {
        state.selectedExtras.push(payload)
      }
    },
    SELECT_SUBSCRIPTION (state, { payload }) {
      if (state.subscriptionsById[payload]) {
        state.selectedSubscription = payload
      }
    },
    REQUEST_SUBSCRIPTIONS (state, { payload }) {
      state.loading = true
    },
    REQUEST_SUBSCRIPTIONS_SUCCESS (state, { payload }) {
      state.subscriptionsById = keyBy(payload, '_id')
      if (payload.length > 0) {
        state.selectedSubscription = payload[0]._id
      }
      state.loading = false
    },
    REQUEST_PAYMENTS (state, { payload }) {
      state.loadingPaymentHistory = true
    },
    REQUEST_PAYMENTS_SUCCESS (state, { payload }) {
      state.paymentsBySubscription = groupBy(payload, 'subscription')
      state.loadingPaymentHistory = false
    },
    REQUEST_NEXT_PAYMENT (state, { payload }) {
      state.loadingNextPayment = true
    },
    REQUEST_NEXT_PAYMENT_SUCCESS (state, { payload }) {
      state.nextPayment = payload
      state.loadingNextPayment = false
    },
    UPDATE_ADDRESS (state, { payload }) {
      state.address = payload
    },
    UPDATE_POSTCODE (state, { payload }) {
      state.postcode = payload
    },
    UPDATE_FULLNAME (state, { payload }) {
      state.fullName = payload
    },
    UPDATE_TEL (state, { payload }) {
      state.tel = payload
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestSubscriptions ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/user/${payload}/subscriptions`
    )
    yield put(actions.REQUEST_SUBSCRIPTIONS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestPayments ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/user/${payload.userID}/payments`,
      {
        params: {
          subscriptionID: payload.subscriptionID
        }
      }
    )
    yield put(actions.REQUEST_PAYMENTS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestNextPayment ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/user/${payload.userID}/payments/next`,
      {
        params: {
          subscriptionID: payload.subscriptionID
        }
      }
    )
    yield put(actions.REQUEST_NEXT_PAYMENT_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('customer/REQUEST_SUBSCRIPTIONS', onRequestSubscriptions)
  yield takeLatest('customer/REQUEST_PAYMENTS', onRequestPayments)
  yield takeLatest('customer/REQUEST_NEXT_PAYMENT', onRequestNextPayment)
  yield takeLatest('customer/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_PAYMENTS, REQUEST_NEXT_PAYMENT, REQUEST_SUBSCRIPTIONS, SELECT_PLAN, SELECT_EXTRA, SELECT_SUBSCRIPTION, UPDATE_TEL, UPDATE_FULLNAME, UPDATE_POSTCODE, UPDATE_ADDRESS } = actions

export default reducer
