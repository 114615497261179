import { connect } from 'react-redux'
import { REQUEST_SUBSCRIPTIONS, SELECT_SUBSCRIPTION } from '../ducks/customer'
import CustomerDashboard from '../components/CustomerDashboard'

const mapStateToProps = state => {
  return {
    userID: state.auth.currentUser,
    loading: state.customer.loading,
    subscriptions: Object.values(state.customer.subscriptionsById),
    selectedSubscription: state.customer.selectedSubscription && state.customer.subscriptionsById[state.customer.selectedSubscription]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestSubscriptions: userID => dispatch(REQUEST_SUBSCRIPTIONS(userID)),
    selectSubscription: subscriptionID => dispatch(SELECT_SUBSCRIPTION(subscriptionID))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDashboard)
