import React, { useState } from 'react'

import { planPropType, extraPropType } from '../../types'
import Button from '../Button'
import styles from './MyPlan.module.scss'
import Modal from '../Modal'
import PhoneUs from '../PhoneUs'

const MyPlan = ({ plan, products }) => {
  const [modalVisible, setModalVisible] = useState(false)
  const extraCosts = products ? products.reduce((p, c) => c.hasSubscription ? c.monthlyCost + p : p, 0) : 0
  return (
    <div className={styles.root}>
      <h2 className={styles.subheading}>Your current plan</h2>
      <h1 className={styles.planName}>{plan ? plan.name : 'No Plan'}</h1>
      {plan && <h3 className={styles.price}>
        <span>£{(plan.monthlyCost + extraCosts) / 100}</span>/month
        {extraCosts ? ' (inc. extras)' : ''}
      </h3>}
      {plan && <Button secondary size="small" onClick={() => setModalVisible(true)}>Change plan</Button>}
      <Modal visible={modalVisible} onHide={() => setModalVisible(false)}>
        <PhoneUs />
      </Modal>
    </div>
  )
}

MyPlan.propTypes = {
  plan: planPropType,
  products: extraPropType
}

export default MyPlan
