import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IoMdArrowBack } from 'react-icons/io'

import { stagePropType } from '../../types'
import LineDataInput from '../../containers/LineDataInput'
import AddressPicker from '../../containers/AddressPicker'
import PlanSelect from '../../containers/PlanSelect'
import ExtrasSelect from '../../containers/ExtrasSelect'
import PurchasePlan from '../../containers/PurchasePlan'
import styles from './AvailabilityCheck.module.scss'

const stageComponents = {
  INITIAL_INPUT: LineDataInput,
  ADDRESS_SELECT: AddressPicker,
  PLAN_SELECT: PlanSelect,
  EXTRAS_SELECT: ExtrasSelect,
  PLAN_PURCHASE: PurchasePlan
}

const titles = {
  INITIAL_INPUT: 'Availability Checker',
  ADDRESS_SELECT: 'Availability Checker',
  PLAN_SELECT: 'Results',
  EXTRAS_SELECT: 'Extras',
  PLAN_PURCHASE: 'Order Summary'
}

const AvailabilityCheck = ({ stage, logout }) => {
  const StageComponent = stageComponents[stage]
  useEffect(() => {
    logout()
  }, [logout])
  return (
    <div className={styles.root}>
      <img src="/logo_color.png" alt="Logo" className={styles.logo} />
      <h1 className={styles.heading}>{titles[stage]}</h1>
      <div className={styles.container}>
        <StageComponent />
      </div>
      <footer className={styles.footer}>
        <a href="https://gamingbroadband.com/" className={styles.backLink}><IoMdArrowBack / > Back to site</a>
      </footer>
    </div>
  )
}

AvailabilityCheck.propTypes = {
  stage: stagePropType,
  logout: PropTypes.func.isRequired

}

export default AvailabilityCheck
