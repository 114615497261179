import { connect } from 'react-redux'

import { UPDATE_STAGE } from '../ducks/availability'
import { UPDATE_TEL, UPDATE_POSTCODE } from '../ducks/customer'
import LineDataInput from '../components/LineDataInput'

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateTel: tel => dispatch(UPDATE_TEL(tel)),
    updatePostcode: postcode => dispatch(UPDATE_POSTCODE(postcode)),
    nextStage: stage => dispatch(UPDATE_STAGE('ADDRESS_SELECT'))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LineDataInput)
