import React from 'react'

import styles from './PhoneUs.module.scss'

const PhoneUs = () => {
  return (
    <div className={styles.root}>
      <img src="/phone_us.svg" className={styles.image} alt="Phone Us" />
      <h2 className={styles.title}>We're here to help</h2>
      <p className={styles.text}>We are working hard on allowing you to make changes to your plan and direct debit online.</p>
      <p className={styles.text}>In the meantime, we'll be happy to assist you over the phone. Just give us a ring on the number below</p>
      <p className={styles.number}>0161 260 1337</p>
    </div>
  )
}

PhoneUs.propTypes = {
}

export default PhoneUs
