import { connect } from 'react-redux'

import { COMPLETE_ORDER } from '../ducks/orders'
import { RESET_PASSWORD } from '../ducks/auth'
import OrderComplete from '../components/OrderComplete'

const mapStateToProps = state => {
  return {
    loggedIn: !!state.auth.currentUser,
    userName: state.orders.userName,
    userEmail: state.orders.userEmail,
    pwToken: state.orders.pwToken,
    completingOrder: state.orders.loading,
    error: state.orders.error,
    loading: state.auth.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: stage => dispatch(RESET_PASSWORD(stage)),
    completeOrder: ({ orderID, flowID }) => dispatch(COMPLETE_ORDER({ orderID, flowID }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderComplete)
