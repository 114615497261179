import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { GrMoney } from 'react-icons/gr'

import Loadingblock from '../Loadingblock'
import Button from '../Button'
import styles from './NextPaymentCard.module.scss'
import Modal from '../Modal'
import PhoneUs from '../PhoneUs'

const NextPaymentCard = ({ loading, userID, subscriptionID, nextPayment, requestNextPayment }) => {
  const [modalVisible, setModalVisible] = useState(false)
  useEffect(() => {
    requestNextPayment({ userID, subscriptionID })
  }, [userID, subscriptionID, requestNextPayment])
  if (!loading && !nextPayment) return ''
  return (
    <div className={styles.root}>
      <h2 className={styles.subheading}>Next payment:</h2>
      <h1 className={styles.amount}>
        {loading
          ? <Loadingblock style={{ maxWidth: '50%' }}/>
          : `£${nextPayment.amount / 100}`}
      </h1>
      <h3 className={styles.date}>
        on <span>{loading
          ? <Loadingblock style={{ maxWidth: '60%' }} />
          : `${moment(nextPayment.date).format('Do MMM, YYYY')}`}</span>
      </h3>
      <Button size="small" secondary onClick={() => setModalVisible(true)} style={{ marginTop: 'auto', marginRight: 'auto' }}>Manage payments</Button>
      <div className={styles.icon}>
        <GrMoney />
      </div>
      <Modal visible={modalVisible} onHide={() => setModalVisible(false)}>
        <PhoneUs />
      </Modal>
    </div>
  )
}

NextPaymentCard.propTypes = {
  loading: PropTypes.bool,
  userID: PropTypes.string,
  subscriptionID: PropTypes.string,
  requestNextPayment: PropTypes.func,
  nextPayment: PropTypes.shape({
    date: PropTypes.string,
    amount: PropTypes.number
  })
}

export default NextPaymentCard
