import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'

import { paymentPropType } from '../../types'
import Table from '../Table'
import styles from './PaymentHistory.module.scss'

const statuses = {
  CREATED: <span className={classnames(styles.badge, styles.amber)}>Pending</span>,
  CONFIRMED: <span className={classnames(styles.badge, styles.green)}>Paid</span>,
  REFUNDED: <span className={classnames(styles.badge, styles.blue)}>Reunded</span>,
  FAILED: <span className={classnames(styles.badge, styles.red)}>Failed</span>
}

const tableCols = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: ({ status }) => statuses[status]
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: ({ amount }) => '£' + (amount / 100).toFixed(2)
  },
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY')
  }
]

const PaymentHistory = ({ loading, userID, subscriptionID, payments, requestPayments }) => {
  useEffect(() => {
    requestPayments({ userID, subscriptionID })
  }, [requestPayments, userID, subscriptionID])
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Payment History</h2>
      <div style={{ overflowX: 'auto' }}>
        <Table
          columns={tableCols}
          data={payments}
          sortable={false}
          loadingBars={6}
          loading={loading}
          noContent={{
            title: 'No payments yet',
            text: 'Payments will appear here once you start getting charged.',
            image: '/empty_payments.svg'
          }}
        />
      </div>
    </div>
  )
}

PaymentHistory.propTypes = {
  loading: PropTypes.bool,
  userID: PropTypes.string,
  subscriptionID: PropTypes.string,
  requestPayments: PropTypes.func,
  payments: PropTypes.arrayOf(paymentPropType)
}

export default PaymentHistory
