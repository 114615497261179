import React from 'react'

import styles from './MyInstallations.module.scss'
import Button from '../Button'

const MyInstallations = () => {
  return (
    <div className={styles.root}>
      <img className={styles.emptyImage} src="/network_installer.png" alt="Network Installation Technician"/>
      <h3 className={styles.emptyTitle}>Let&apos;s get you connected</h3>
      <p className={styles.emptyText}>Our engineers are waiting to get you set up, just tell us when.</p>
      <Button>Book Installation</Button>
    </div>
  )
}

MyInstallations.propTypes = {
}

export default MyInstallations
