import React from 'react'
import { IoIosCheckmarkCircleOutline, IoIosAddCircleOutline } from 'react-icons/io'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { extraPropType } from '../../types'
import styles from './ExtrasSelect.module.scss'

const ExtraCard = ({ extra, onSelect, selected }) => (
  <div className={styles.extraCard} onClick={onSelect}>
    <div className={classnames(styles.extraIndicator, { [styles.extraSelectedIndicator]: selected })}>
      {selected
        ? <IoIosCheckmarkCircleOutline />
        : <IoIosAddCircleOutline />
      }
    </div>
    <div className={styles.extraContent}>
      <h6 className={styles.extraName}>
        {extra.name}
      </h6>
      <p className={styles.extraPricing}>
        {extra.price ? `£${extra.price / 100}` : ''}
        {extra.price && extra.monthlyCost ? ' + ' : ''}
        {extra.monthlyCost ? `£${extra.monthlyCost / 100}/month` : ''}
      </p>
      <p className={styles.extraDescription}>{extra.description}</p>
    </div>
  </div>
)

ExtraCard.propTypes = {
  selected: PropTypes.bool,
  extra: extraPropType,
  onSelect: PropTypes.func
}

export default ExtraCard
