import { connect } from 'react-redux'
import { RESET_PASSWORD } from '../ducks/auth'
import ResetPassword from '../components/ResetPassword'

const mapStateToProps = state => {
  return {
    loggedIn: !!state.auth.currentUser,
    loading: state.auth.loading,
    error: state.auth.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    resetPassword: ({ email, password, token }) => dispatch(RESET_PASSWORD({ email, password, token }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
