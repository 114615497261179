import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { IoMdArrowBack } from 'react-icons/io'

import { addressResultsPropType } from '../../types'
import LoadingBlock from '../Loadingblock'
import styles from './AddressPicker.module.scss'

const AddressPicker = ({ postcode, loading, addressResults, requestAddresses, updateAddress, updateStage }) => {
  const handleAddressSelect = address => () => {
    updateAddress(address)
    updateStage('PLAN_SELECT')
  }

  const goBack = () => {
    updateStage('INITIAL_INPUT')
  }

  useEffect(() => {
    requestAddresses(postcode)
  }, [requestAddresses, postcode])
  return (
    <div className={styles.root}>
      <p className={styles.helpText}>
        Select your address from the list below:
      </p>
      <div className={styles.list}>
        {loading
          ? new Array(25).fill(0).map((_, i) => <LoadingBlock key={i} style={{ marginBottom: '1rem' }} />)
          : addressResults.map(address => (
            <div role="listitem" key={address.ref} onClick={handleAddressSelect(address)} className={styles.listItem}>
              {address.details}
            </div>
          ))}
      </div>
      <span onClick={goBack} className={styles.back}><IoMdArrowBack /> Back a step</span>
    </div>
  )
}

AddressPicker.propTypes = {
  loading: PropTypes.bool,
  addressResults: addressResultsPropType,
  postcode: PropTypes.string.isRequired,
  requestAddresses: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updateStage: PropTypes.func.isRequired
}

export default AddressPicker
