import React from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './RequestReset.module.scss'
import Input from '../Input'
import Button from '../Button'
import Form from '../Form'
import Field from '../Form/Field'

const RequestReset = ({ requestReset, loading, resetRequested }) => {
  const handleSubmit = ({ values }) => {
    requestReset(values.email)
  }

  if (resetRequested) {
    return <Redirect to="/" />
  }

  return (
    <div className={styles.root}>
      <img src="/logo_color.png" alt="Logo" className={styles.logo}/>
      <Form onSubmit={handleSubmit} className={styles.container}>
        <h1 className={styles.heading}>Reset your password</h1>
        <Field validations={['isEmail']}>
          <Input
            type="email"
            icon="Mail"
            placeholder="Email Address"
            autoComplete="email-address"
            style={{ width: '100%' }}
            name="email" />
        </Field>
        <Button type="submit" loading={loading} style={{ width: '100%' }}>
          {loading ? 'Logging in...' : 'Send reset email'}
        </Button>
      </Form>
    </div>
  )
}

RequestReset.propTypes = {
  requestReset: PropTypes.func,
  loading: PropTypes.bool,
  resetRequested: PropTypes.bool
}

export default RequestReset
