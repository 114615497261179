import styles from './Checkbox.module.scss'
import classNames from 'classnames'
import React, { useState, forwardRef } from 'react'
import PropTypes from 'prop-types'

const Checkbox = forwardRef(({ children, className, partial, onChange, disabled, large, ...otherProps }, ref) => {
  const [isChecked, setChecked] = useState(otherProps.checked !== undefined ? otherProps.checked : otherProps.defaultChecked)
  const handleChange = e => {
    setChecked(!!e.target.checked)
    if (onChange) {
      e.target.value = !!e.target.checked
      return onChange(e)
    }
  }

  const classes = classNames(
    styles.root,
    { [styles.partial]: partial },
    { [styles.checked]: isChecked },
    { [styles.disabled]: disabled },
    { [styles.large]: large }
  )

  const id = otherProps.id || otherProps.key || otherProps.name
  return (
    <div className={`${className || ''} ${classes}`}>
      <input type="checkbox" disabled={disabled} onChange={handleChange} {...otherProps} ref={ref} id={id} />
      <label className={styles.label} htmlFor={id}>{children}</label>
    </div>
  )
})

Checkbox.propTypes = {
  /** Control whether the checkbox is checked or unchecked */
  checked: PropTypes.bool,
  /** Show a partial checked effect */
  partial: PropTypes.bool
}

export default Checkbox
