import PropTypes from 'prop-types'

import { stages } from './ducks/availability'

export const stagePropType = PropTypes.oneOf(Object.values(stages))

export const extraPropType = PropTypes.shape({
  name: PropTypes.string,
  description: PropTypes.string,
  inStock: PropTypes.bool,
  generateInstall: PropTypes.bool,
  price: PropTypes.number,
  monthlyCost: PropTypes.number,
  hasSubscription: PropTypes.bool
})

export const planPropType = PropTypes.shape({
  name: PropTypes.string,
  features: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string,
    icon: PropTypes.string
  })),
  extras: PropTypes.arrayOf(extraPropType),
  websiteLink: PropTypes.string,
  maxDownload: PropTypes.number,
  maxUpload: PropTypes.number,
  estimatedDownload: PropTypes.number,
  estimatedUpload: PropTypes.number,
  monthlyCost: PropTypes.integer,
  installCost: PropTypes.integer,
  contractLength: PropTypes.integer,
  requiresInstall: PropTypes.bool,
  networkTypes: PropTypes.arrayOf(PropTypes.oneOf([
    'FTTP',
    'FTTC',
    'ADSL2PLUS',
    'ADSL2PLUSANNEXM',
    'GFAST',
    'SOGEA'
  ])),
  inactive: PropTypes.bool,
  notes: PropTypes.arrayOf(PropTypes.string)
})

export const planResultsPropType = PropTypes.arrayOf(planPropType)

export const addressPropType = PropTypes.shape({
  details: PropTypes.shape({
    organisationName: PropTypes.string,
    poBox: PropTypes.string,
    subPremises: PropTypes.string,
    premisesName: PropTypes.string,
    thoroughfareNumber: PropTypes.string,
    dependentThoroughfareName: PropTypes.string,
    thoroughfareName: PropTypes.string,
    doubleDependentLocality: PropTypes.string,
    locality: PropTypes.string,
    postTown: PropTypes.string,
    county: PropTypes.string,
    postCode: PropTypes.string,
    country: PropTypes.string
  }).isRequired,
  ref: PropTypes.string.isRequired
})

export const addressResultPropType = PropTypes.shape({
  details: PropTypes.string,
  ref: PropTypes.string
})

export const addressResultsPropType = PropTypes.arrayOf(addressResultPropType)

export const subscriptionPropType = PropTypes.shape({
  _id: PropTypes.string,
  plan: planPropType,
  products: PropTypes.arrayOf(extraPropType),
  failureReason: PropTypes.string,
  status: PropTypes.string
})

export const paymentPropType = PropTypes.shape({
  subscription: PropTypes.string,
  gcID: PropTypes.string,
  failureReason: PropTypes.string,
  amount: PropTypes.number,
  status: PropTypes.oneOf([
    'CREATED',
    'CONFIRMED',
    'REFUNDED',
    'FAILED'
  ])
})
