import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Input from '../Input'
import Button from '../Button'

import styles from './LineDataInput.module.scss'

const isPostcode = postcode => !!postcode.match(/^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? [0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/, 'ig')

const LineDataInput = ({ updatePostcode, updateTel, nextStage }) => {
  const [phone, setPhone] = useState('')
  const [postcode, setPostcode] = useState('')
  const handlePhoneChange = e => setPhone(e.target.value)
  const handlePostcodeChange = e => setPostcode(e.target.value)
  const handleSubmit = () => {
    nextStage()
  }
  const handleKeyUp = e => {
    if (e.keyCode === 13 && isPostcode(postcode)) {
      nextStage()
    }
  }
  useEffect(() => {
    if (isPostcode(postcode)) {
      updatePostcode(postcode)
    }
  }, [postcode, updatePostcode])
  useEffect(() => {
    if (phone.length > 9) {
      updateTel(phone)
    }
  }, [phone, updateTel])
  return (
    <div>
      <p className={styles.helpText}>Enter your postcode or phone number to see what packages we can offer you.</p>
      <div className={styles.controls}>
        <Input className={styles.input} type="tel" placeholder="Phone Number" name="phone" onChange={handlePhoneChange} onKeyUp={handleKeyUp} />
        <Input className={styles.input} type="text" placeholder="Postcode" name="postcode" onChange={handlePostcodeChange} onKeyUp={handleKeyUp} />
        <Button disabled={!isPostcode(postcode)} onClick={handleSubmit}>Check Availability</Button>
      </div>
      {postcode.length > 4 && !postcode.includes(' ') ? (
        <p className={styles.hint}>Make sure you include spaces in your postcode (e.g. SW1A 1AA)</p>
      ) : ''}
    </div>
  )
}

LineDataInput.propTypes = {
  updateTel: PropTypes.func,
  updatePostcode: PropTypes.func,
  nextStage: PropTypes.func
}

export default LineDataInput
