import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { FiHome, FiLogOut, FiUser } from 'react-icons/fi'
import navStyles from './Nav.module.scss'
import PropTypes from 'prop-types'

const Nav = ({ logout }) => (
  <nav className={navStyles.root}>
    <Link to="/">
      <div className={navStyles.logo}>
        <img src="/logo_color.png" alt="logo" />
      </div>
    </Link>
    <ul className={navStyles.links}>
      <NavLink to="/" exact activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiHome /> <span>Account Overview</span>
        </li>
      </NavLink>
      <NavLink to="/account" activeClassName={navStyles.activeLink}>
        <li className={navStyles.link}>
          <FiUser /> <span>My Details</span>
        </li>
      </NavLink>
      {/* <NavLink to="/installations" activeClassName={navStyles.activeLink}> */}
      {/*   <li className={navStyles.link}> */}
      {/*     <FiCalendar /> <span>My Installation</span> */}
      {/*   </li> */}
      {/* </NavLink> */}
      <span onClick={logout} style={{ cursor: 'pointer', marginTop: 'auto' }}>
        <li className={navStyles.link}>
          <FiLogOut /> <span>Logout</span>
        </li>
      </span>
    </ul>
  </nav>
)

Nav.propTypes = {
  logout: PropTypes.func
}

export default Nav
