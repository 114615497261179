import { connect } from 'react-redux'

import { REQUEST_ADDRESSES, UPDATE_STAGE } from '../ducks/availability'
import { UPDATE_ADDRESS } from '../ducks/customer'
import AddressPicker from '../components/AddressPicker'

const mapStateToProps = state => {
  return {
    loading: state.availability.loading,
    addressResults: state.availability.addressResults,
    tel: state.customer.tel,
    postcode: state.customer.postcode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestAddresses: postcode => dispatch(REQUEST_ADDRESSES({ postcode })),
    updateAddress: address => dispatch(UPDATE_ADDRESS(address)),
    updateStage: stage => dispatch(UPDATE_STAGE(stage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressPicker)
