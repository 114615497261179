import { createSlice } from '@reduxjs/toolkit'
import keyBy from 'lodash'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'plans',
  initialState: {
    error: false,
    loading: true,
    byId: {}
  },
  reducers: {
    REQUEST_PLAN (state, { payload }) {
      state.loading = true
    },
    REQUEST_PLANS (state, { payload }) {
      state.loading = true
    },
    REQUEST_PLAN_SUCCESS (state, { payload }) {
      if (payload && payload._id) {
        state.byId[payload._id] = payload
      }
      state.loading = false
    },
    REQUEST_PLANS_SUCCESS (state, { payload }) {
      state.byId = keyBy(payload, '_id')
      state.loading = false
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onRequestPlan ({ payload }) {
  try {
    const { data } = yield call(
      API.get, `/plans/${payload}`
    )
    yield put(actions.REQUEST_PLAN_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestPlans ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/plans', {
        params: payload
      }
    )
    yield put(actions.REQUEST_PLANS_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('plans/REQUEST_PLAN', onRequestPlan)
  yield takeLatest('plans/REQUEST_PLANS', onRequestPlans)
  yield takeLatest('plans/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_PLAN, REQUEST_PLANS } = actions

export default reducer
