import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import 'normalize.css'

import App from './App'
import * as serviceWorker from './serviceWorker'
import WithReduxStore from './WithReduxStore'

ReactDOM.render(
  <Router>
    <WithReduxStore>
      <App />
    </WithReduxStore>
  </Router>,
  document.getElementById('root'))

serviceWorker.unregister()
