import { connect } from 'react-redux'

import { UPDATE_STAGE } from '../ducks/availability'
import { CREATE_ORDER } from '../ducks/orders'
import PurchasePlan from '../components/PurchasePlan'

const mapStateToProps = state => {
  return {
    plan: state.customer.selectedPlan,
    extras: state.customer.selectedExtras,
    postcode: state.customer.postcode,
    redirectURL: state.orders.redirectURL,
    loading: state.orders.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateStage: stage => dispatch(UPDATE_STAGE(stage)),
    createOrder: ({ planID, extraIDs, postcode }) => dispatch(CREATE_ORDER({ planID, extraIDs, postcode }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PurchasePlan)
