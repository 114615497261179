/* global window */
import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { LOGIN_RESTORED } from './ducks/auth'

import './styles/global.scss'
import Layout from './containers/Layout'
import PrivateRoute from './containers/PrivateRoute'
import Login from './containers/Login'
import AvailabilityCheck from './containers/AvailabilityCheck'
import CompleteOrder from './containers/CompleteOrder'
import CustomerDashboard from './containers/CustomerDashboard'
import MyDetails from './containers/MyDetails'
import MyInstallations from './components/MyInstallations'
import ResetPassword from './containers/ResetPassword'
import RequestReset from './containers/RequestReset'

const mapDispatchToProps = dispatch => {
  return {
    restoreSession: ({ id, token }) => dispatch(LOGIN_RESTORED({ id, token }))
  }
}

class AppRoutes extends React.Component {
  constructor (props) {
    super(props)
    const currentUser = window.localStorage.getItem('currentUser')
    if (currentUser) {
      this.props.restoreSession(JSON.parse(currentUser))
    }
  }

  render () {
    return (
      <Layout>
        <Switch>
          <PrivateRoute path='/' exact component={CustomerDashboard} />
          <Route path='/login' component={Login} />
          <Route path='/order/complete' component={CompleteOrder} />
          <Route path='/order' component={AvailabilityCheck} />
          <Route path='/account' component={MyDetails} />
          <Route path='/installations' component={MyInstallations} />
          <Route path='/reset' component={ResetPassword} />
          <Route path='/forgot-password' component={RequestReset} />
        </Switch>
      </Layout>
    )
  }
}

AppRoutes.propTypes = {
  restoreSession: PropTypes.func
}

export default connect(null, mapDispatchToProps)(AppRoutes)
