import React, { useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './OrderComplete.module.scss'
import Input from '../Input'
import Button from '../Button'
import Form from '../Form'
import Field from '../Form/Field'

const OrderComplete = ({ userName, loggedIn, userEmail, pwToken, completingOrder, error, loading, resetPassword, completeOrder }) => {
  const [problem, setProblem] = useState(false)
  const formRef = useRef()
  const handleKeyUp = e => {
    setProblem(false)
    if (e.keyCode === 13) {
      // submit when return key is pressed
      formRef.current.submit(e)
    }
  }

  const handleSubmit = ({ values }) => {
    if (values.password === values.passwordConfirm) {
      resetPassword({ email: userEmail, password: values.password, token: pwToken })
    } else {
      setProblem(true)
    }
  }

  useEffect(() => {
    if (!completingOrder && !error && !userEmail) {
      const params = (new URL(document.location)).searchParams
      const flowID = params.get('redirect_flow_id')
      const orderID = params.get('order_id')
      completeOrder({ orderID, flowID })
    }
  }, [completeOrder, completingOrder, error, userEmail])

  if (error) {
    window.location.href = '/'
  }

  if (loggedIn) {
    return <Redirect to="/" />
  }

  if (completingOrder || !userEmail) {
    return (
      <div className={styles.root}>
        <img src="/logo.png" alt="Logo" className={styles.logo}/>
        <div className={styles.container}>
          <h3 className={styles.loadingTitle}>Completing your order</h3>
          <img src="/bars.svg" className={styles.loadingIndicator} alt="loading" />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <img src="/logo.png" alt="Logo" className={styles.logo} />
      <div className={styles.container}>
        <h2 className={styles.title}>Almost there {userName}!</h2>
        <p className={styles.text}>You&apos;re all set up with a your plan. You just need to pick a password so that we can log you in.</p>
        <p className={styles.hint}>Enter a strong passsword below. Try not to re-use passwords from other sites.</p>
        <Form ref={formRef} onSubmit={handleSubmit} className={styles.form}>
          <Field validations={['isEmail']}>
            <Input
              onKeyUp={handleKeyUp}
              type="email"
              icon="Mail"
              placeholder="Email Address"
              autoComplete="email-address"
              value={userEmail}
              disabled
              style={{ width: '100%' }}
              name="email" />
          </Field>
          <Field>
            <Input
              onKeyUp={handleKeyUp}
              type="password"
              icon="Lock"
              placeholder="Password"
              autoComplete="current-password"
              style={{ width: '100%' }}
              name="password" />
          </Field>
          <Field>
            <Input
              onKeyUp={handleKeyUp}
              type="password"
              icon="Lock"
              placeholder="Confirm Password"
              autoComplete="current-password"
              style={{ width: '100%' }}
              name="passwordConfirm" />
          </Field>
          {problem && <span className={styles.error}>Both passwords must match</span>}
          <Button type="submit" loading={loading} style={{ width: '100%' }}>
            {loading ? 'Logging in...' : 'Create your Account'}
          </Button>
        </Form>
      </div>
    </div>
  )
}

OrderComplete.propTypes = {
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  pwToken: PropTypes.string,
  completingOrder: PropTypes.bool,
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  error: PropTypes.bool,
  resetPassword: PropTypes.func,
  completeOrder: PropTypes.func
}

export default OrderComplete
