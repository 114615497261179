import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

const { actions, reducer } = createSlice({
  name: 'orders',
  initialState: {
    error: false,
    loading: false,
    redirctURL: null,
    userName: null,
    userEmail: null,
    pwToken: null
  },
  reducers: {
    CREATE_ORDER (state, { payload }) {
      state.loading = true
    },
    COMPLETE_ORDER (state, { payload }) {
      state.loading = true
    },
    CREATE_ORDER_SUCCESS (state, { payload }) {
      state.loading = false
      if (payload) {
        state.redirectURL = payload
      }
    },
    COMPLETE_ORDER_SUCCESS (state, { payload }) {
      state.loading = false
      state.userName = payload.userName
      state.userEmail = payload.userEmail
      state.pwToken = payload.pwToken
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    }
  }
})

function * onCreateOrder ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/orders/create', payload
    )
    yield put(actions.CREATE_ORDER_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onCompleteOrder ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/orders/complete', payload
    )
    yield put(actions.COMPLETE_ORDER_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('orders/CREATE_ORDER', onCreateOrder)
  yield takeLatest('orders/COMPLETE_ORDER', onCompleteOrder)
  yield takeLatest('orders/REQUEST_FAILED', onRequestFailed)
}

export const { CREATE_ORDER, COMPLETE_ORDER } = actions

export default reducer
