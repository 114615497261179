import { connect } from 'react-redux'
import { REQUEST_NEXT_PAYMENT } from '../ducks/customer'
import NextPaymentCard from '../components/NextPaymentCard'

const mapStateToProps = state => {
  return {
    loading: state.customer.loadingNextPayment,
    nextPayment: state.customer.nextPayment
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestNextPayment: ({ userID, subscriptionID }) => dispatch(REQUEST_NEXT_PAYMENT({ userID, subscriptionID }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NextPaymentCard)
