import { connect } from 'react-redux'
import { LOGOUT } from '../ducks/auth'
import Nav from '../components/Nav'

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(LOGOUT())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav)
