import { connect } from 'react-redux'
import { REQUEST_PASSWORD_RESET } from '../ducks/auth'
import RequestReset from '../components/RequestReset'

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    resetRequested: state.auth.resetRequested
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestReset: email => dispatch(REQUEST_PASSWORD_RESET(email))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestReset)
