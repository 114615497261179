/* global localStorage */
import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import { SHOW_MESSAGE } from './messages'
import API, { updateToken } from '../api'

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState: {
    currentUser: false,
    resetRequested: false,
    error: false,
    loading: false,
    loggingIn: false,
    byId: {}
  },
  reducers: {
    LOGIN_ATTEMPT (state) {
      state.loggingIn = true
    },
    LOGIN_SUCCESS (state, { payload }) {
      state.loading = false
      state.currentUser = payload.id
      state.loggingIn = false
      state.byId[payload.id] = payload
    },
    LOGIN_FAILED (state) {
      state.currentUser = null
      state.loggingIn = false
      state.auth = null
    },
    REQUEST_PASSWORD_RESET (state) {
      state.loading = true
    },
    REQUEST_PASSWORD_RESET_SUCCESS (state) {
      state.resetRequested = true
      state.loading = false
    },
    RESET_PASSWORD (state) {
      state.loading = true
    },
    RESET_FAILED (state) {
      state.error = true
    },
    LOGOUT (state) {
      state.currentUser = null
    },
    LOGIN_RESTORED (state, { payload }) {
      state.currentUser = payload.id
      state.byId[payload.id] = payload
    }
  }
})

// we lose our context when trying to call localStorage.X
// inside these functions if we don't do this
const setItem = window.localStorage.setItem.bind(localStorage)
const removeItem = window.localStorage.removeItem.bind(localStorage)

function * onLoginAttempt ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/auth/login', {
        email: payload.email,
        password: payload.password
      }
    )
    // assume login successful
    yield put(actions.LOGIN_SUCCESS(data))
  } catch (err) {
    yield put(actions.LOGIN_FAILED({ message: err.message }))
  }
}

function * onLoginSuccess ({ payload }) {
  yield call(updateToken, payload.token)
  yield put(SHOW_MESSAGE({ status: 'success', title: 'Logged in' }))
  yield call(setItem, 'currentUser', JSON.stringify(payload))
}

function * onRequestReset ({ payload }) {
  try {
    yield call(
      API.post, '/auth/requestreset', {
        email: payload
      }
    )
    yield put(actions.REQUEST_PASSWORD_RESET_SUCCESS())
    yield put(SHOW_MESSAGE({ status: 'success', title: 'If this account exists, you will receive an email' }))
  } catch (err) {
    yield put(actions.RESET_FAILED({ message: err.message }))
  }
}

function * onResetPassword ({ payload }) {
  try {
    const { data } = yield call(
      API.post, '/auth/resetpassword', {
        email: payload.email,
        password: payload.password,
        token: payload.token
      }
    )
    yield put(actions.LOGIN_SUCCESS(data))
  } catch (err) {
    yield put(actions.RESET_FAILED({ message: err.message }))
  }
}

function * onLoginFailed ({ payload }) {
  yield put(SHOW_MESSAGE({ status: 'error', title: 'Login Failed', text: 'Check your email/password and try again' }))
}

function * onResetFailed ({ payload }) {
  yield put(SHOW_MESSAGE({ status: 'error', title: 'Reset failed', text: 'Please try again' }))
}

function * onLogout ({ payload }) {
  yield call(removeItem, 'currentUser')
}

function * onLoginRestored ({ payload }) {
  yield call(updateToken, payload.token)
  try {
    yield call(API.get, '/auth/validateToken')
  } catch (err) {
    yield put(actions.LOGOUT())
  }
}

export function * saga () {
  yield takeLatest('auth/LOGIN_ATTEMPT', onLoginAttempt)
  yield takeLatest('auth/LOGIN_SUCCESS', onLoginSuccess)
  yield takeLatest('auth/LOGIN_FAILED', onLoginFailed)
  yield takeLatest('auth/LOGIN_RESTORED', onLoginRestored)
  yield takeLatest('auth/REQUEST_PASSWORD_RESET', onRequestReset)
  yield takeLatest('auth/RESET_PASSWORD', onResetPassword)
  yield takeLatest('auth/RESET_FAILED', onResetFailed)
  yield takeLatest('auth/LOGOUT', onLogout)
}

export const { LOGIN_ATTEMPT, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, LOGIN_RESTORED, REQUEST_PASSWORD_RESET, RESET_PASSWORD } = actions

export default reducer
