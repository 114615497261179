import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { planResultsPropType, addressResultPropType } from '../../types'
import styles from './PlanSelect.module.scss'
import PlanCard from './PlanCard'

const PlanSelect = ({ loading, availablePlans, tel, address, requestPlans, noDeals, selectPlan, updateStage }) => {
  useEffect(() => {
    const params = { address: address.ref }
    if (tel) params.tel = tel
    requestPlans(params)
  }, [address.ref, requestPlans, tel])

  const handleSelect = plan => () => {
    selectPlan(plan)
    updateStage(plan.extras && plan.extras.length > 0 ? 'EXTRAS_SELECT' : 'PLAN_PURCHASE')
  }

  if (loading) {
    return (
      <div className={styles.root}>
        <h3 className={styles.loadingTitle}>Searching for deals&hellip;</h3>
        <img src="/bars.svg" className={styles.loadingIndicator} alt="loading" />
      </div>
    )
  }

  if (noDeals) {
    return (
      <div style={{ textAlign: 'center' }}>
        <h3 className={styles.title} style={{ margin: '0 auto 2rem', maxWidth: '35rem' }}>Sorry, we don&apos;t currently service your area.</h3>
        <p className={styles.subtext} style={{ maxWidth: '35rem' }}>However, we&apos;re constantly expanding to new areas, be sure to check back regularly.</p>
        <a href="https://gamingbroadband.com">Go Home</a>
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Here&apos;s what we found</h3>
      <p className={styles.subtext}>Speeds are estimated based on your line</p>
      <div className={styles.container}>
        {availablePlans.map(plan => (
          <PlanCard key={plan._id} plan={plan} onSelect={handleSelect(plan)} />
        ))}
      </div>
    </div>
  )
}

PlanSelect.propTypes = {
  loading: PropTypes.bool,
  availablePlans: planResultsPropType,
  noDeals: PropTypes.bool,
  tel: PropTypes.string,
  address: addressResultPropType,
  requestPlans: PropTypes.func,
  selectPlan: PropTypes.func,
  updateStage: PropTypes.func
}

export default PlanSelect
