import { connect } from 'react-redux'
import { REQUEST_PLANS, UPDATE_STAGE } from '../ducks/availability'
import { LOGOUT } from '../ducks/auth'
import AvailabilityCheck from '../components/AvailabilityCheck'

const mapStateToProps = state => {
  return {
    availabilePlans: state.availability.availabilePlans,
    stage: state.availability.stage,
    loading: state.availability.loading
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPlans: query => dispatch(REQUEST_PLANS(query)),
    logout: () => dispatch(LOGOUT()),
    updateStage: stage => dispatch(UPDATE_STAGE(stage))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityCheck)
