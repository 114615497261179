import React, { useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import styles from './ResetPassword.module.scss'
import Input from '../Input'
import Button from '../Button'
import Form from '../Form'
import Field from '../Form/Field'

const ResetPassword = ({ resetPassword, loggedIn, loading, error }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const pwToken = urlParams.get('c')
  const [problem, setProblem] = useState(false)
  const formRef = useRef()
  const handleKeyUp = e => {
    setProblem(false)
    if (e.keyCode === 13) {
      // submit when return key is pressed
      formRef.current.submit(e)
    }
  }

  const handleSubmit = ({ values }) => {
    if (values.password === values.passwordConfirm) {
      resetPassword({ email: values.email, password: values.password, token: pwToken })
    } else {
      setProblem(true)
    }
  }

  if (error) {
    return <Redirect to="/" />
  }

  if (loggedIn) {
    return <Redirect to="/" />
  }

  return (
    <div className={styles.root}>
      <img src="/logo_color.png" alt="Logo" className={styles.logo}/>
      <Form ref={formRef} onSubmit={handleSubmit} className={styles.container}>
        <h1 className={styles.heading}>Reset your password</h1>
        <Field validations={['isEmail']}>
          <Input
            onKeyUp={handleKeyUp}
            type="email"
            icon="Mail"
            placeholder="Email Address"
            autoComplete="email-address"
            style={{ width: '100%' }}
            name="email" />
        </Field>
        <Field>
          <Input
            onKeyUp={handleKeyUp}
            type="password"
            icon="Lock"
            placeholder="New Password"
            autoComplete="current-password"
            style={{ width: '100%' }}
            name="password" />
        </Field>
        <Field>
          <Input
            onKeyUp={handleKeyUp}
            type="password"
            icon="Lock"
            placeholder="Confirm Password"
            autoComplete="current-password"
            style={{ width: '100%' }}
            name="passwordConfirm" />
        </Field>
        {problem && <span className={styles.error}>Both passwords must match</span>}
        <Button type="submit" loading={loading} style={{ width: '100%' }}>
          {loading ? 'Logging in...' : 'Reset Password'}
        </Button>
      </Form>
    </div>
  )
}

ResetPassword.propTypes = {
  resetPassword: PropTypes.func,
  loggedIn: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.bool
}

export default ResetPassword
