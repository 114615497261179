import React from 'react'
import { Provider } from 'react-redux'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import PropTypes from 'prop-types'

import authReducer, { saga as authSaga } from './ducks/auth'
import availabilityReducer, { saga as availabilitySaga } from './ducks/availability'
import ordersReducer, { saga as ordersSaga } from './ducks/orders'
import plansReducer, { saga as plansSaga } from './ducks/plans'
import messageReducer from './ducks/messages'
import customerReducer, { saga as customerSaga } from './ducks/customer'
import usersReducer, { saga as usersSaga } from './ducks/users'

const sagaMiddleware = createSagaMiddleware()

function * rootSaga () {
  yield all([
    authSaga(),
    availabilitySaga(),
    ordersSaga(),
    customerSaga(),
    usersSaga(),
    plansSaga()
  ])
}

const middleware = [
  ...getDefaultMiddleware(),
  sagaMiddleware
]
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger)
}
export const store = configureStore({
  reducer: {
    auth: authReducer,
    messages: messageReducer,
    availability: availabilityReducer,
    customer: customerReducer,
    orders: ordersReducer,
    users: usersReducer,
    plans: plansReducer
  },
  middleware
})

sagaMiddleware.run(rootSaga)

const ReduxStore = ({ children }) => (
  <Provider store={store}>
    {children}
  </Provider>
)

ReduxStore.propTypes = {
  children: PropTypes.node
}

export default ReduxStore
