import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { subscriptionPropType } from '../../types'
import styles from './CustomerDashboard.module.scss'
import MyPlan from '../MyPlan'
import NextPaymentCard from '../../containers/NextPayment'
import PaymentHistory from '../../containers/PaymentHistory'

const CustomerDashboard = ({ userID, loading, subscriptions, selectedSubscription, requestSubscriptions, selectSubscription }) => {
  useEffect(() => {
    requestSubscriptions(userID)
  }, [requestSubscriptions, userID])

  if (loading) return ''

  return (
    <div className={styles.root}>
      <h1 className={styles.title}>Your Account</h1>
      <div className={styles.row}>
        <MyPlan plan={selectedSubscription && selectedSubscription.plan} products={selectedSubscription && selectedSubscription.products} />
        {selectedSubscription && <NextPaymentCard userID={userID} subscriptionID={selectedSubscription._id} />}
      </div>
      {selectedSubscription && <PaymentHistory userID={userID} subscriptionID={selectedSubscription._id} />}
    </div>
  )
}

CustomerDashboard.propTypes = {
  loading: PropTypes.bool,
  userID: PropTypes.string,
  subscriptions: PropTypes.arrayOf(subscriptionPropType),
  selectedSubscription: subscriptionPropType,
  requestSubscriptions: PropTypes.func,
  selectSubscription: PropTypes.func
}

export default CustomerDashboard
