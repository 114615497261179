import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { planPropType, extraPropType } from '../../types'
import styles from './PurchasePlan.module.scss'

const PurchasePlan = ({ plan, extras, postcode, createOrder, loading, redirectURL, updateStage }) => {
  const [termsAccepted, setTermsAccepted] = useState(false)
  const installCost = plan.requiresInstall ? plan.installCost : 0
  const payNowTotal = extras.reduce((p, c) => c.price + p, 0) + installCost
  const monthlyTotal = extras.reduce((p, c) => c.hasSubscription ? c.monthlyCost + p : p, 0) + plan.monthlyCost
  const toggleTerms = () => {
    setTermsAccepted(!termsAccepted)
  }
  const handleSubmit = () => {
    createOrder({
      planID: plan._id,
      extraIDs: extras.map(extra => extra._id),
      postcode: postcode
    })
  }
  useEffect(() => {
    if (redirectURL) {
      window.location.href = redirectURL
    }
  }, [redirectURL])
  return (
    <div className={styles.root}>
      <h2 className={styles.title}>Order details</h2>
      <table className={styles.orderItems}>
        <tr className={styles.orderItem}><td>{plan.name} (12 months)</td><td>£{plan.monthlyCost / 100}/month</td></tr>
        {plan.requiresInstall && <tr className={styles.orderItem}><td>Installation</td><td>£{plan.installCost / 100}</td></tr>}
        {extras.map(extra => (
          <tr key={extra._id} className={styles.orderItem}>
            <td>{extra.name}</td>
            <td>
              {extra.price ? `£${extra.price / 100}` : ''}
              {extra.price && extra.monthlyCost ? ' + ' : ''}
              {extra.monthlyCost ? `£${extra.monthlyCost / 100}/month` : ''}
            </td>
          </tr>
        ))}
      </table>
      <h2 className={styles.title}>Order total</h2>
      <table className={styles.orderItems}>
        {payNowTotal > 0 ? <tr className={styles.orderItem}><td>To pay now:</td><td>£{payNowTotal / 100}</td></tr> : ''}
        <tr className={styles.orderItem}><td>Monthly cost:</td><td>£{monthlyTotal / 100}</td></tr>
      </table>
      <p className={styles.info}>You will now be taken to our payment partner to set up a Direct Debit on a secure payment page.</p>
      <div className={styles.terms}>
        <label htmlFor="terms">
          <input type="checkbox" name="terms" id="terms" checked={termsAccepted} onChange={toggleTerms} /> I accept the <a className={styles.termslink} href='https://gamingbroadband.com/terms' target="_blank" rel="noreferrer noopener">Terms &amp; conditions</a>
        </label>
      </div>
      <div className={styles.actions}>
        <Button disabled={loading || !termsAccepted} onClick={handleSubmit}>Looks good!</Button>
        <Button disabled={loading} secondary onClick={() => updateStage('PLAN_SELECT')}>Go back</Button>
      </div>
    </div>
  )
}

PurchasePlan.propTypes = {
  plan: planPropType,
  extras: [extraPropType],
  postcode: PropTypes.string,
  loading: PropTypes.bool,
  redirectURL: PropTypes.string,
  updateStage: PropTypes.func,
  createOrder: PropTypes.func
}

export default PurchasePlan
