import { connect } from 'react-redux'
import { REQUEST_ME, UPDATE_USER } from '../ducks/users'
import MyDetails from '../components/MyDetails'

const mapStateToProps = state => {
  return {
    me: state.users.me,
    loading: state.users.loading,
    submitting: state.users.submitting
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestMe: () => dispatch(REQUEST_ME()),
    updateMe: patch => dispatch(UPDATE_USER(patch))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyDetails)
