import React from 'react'
import PropTypes from 'prop-types'

import { extraPropType } from '../../types'
import Button from '../Button'
import styles from './ExtrasSelect.module.scss'
import ExtraCard from './ExtraCard'

const ExtrasSelect = ({ extras, updateStage, selectExtra, selectedExtras }) => {
  const handleSelect = extra => () => {
    selectExtra(extra)
  }

  const handleSubmit = () => updateStage('PLAN_PURCHASE')

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Any extras?</h3>
      <div className={styles.container}>
        {extras.map(extra => (
          <ExtraCard extra={extra} key={extra._id} onSelect={handleSelect(extra)} selected={selectedExtras.includes(extra._id)} />
        ))}
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button style={{ display: 'inline-block' }} onClick={handleSubmit} secondary={selectedExtras.length < 1}>{selectedExtras.length ? 'Continue' : 'No thanks'}</Button>
      </div>
    </div>
  )
}

ExtrasSelect.propTypes = {
  extras: PropTypes.arrayOf(extraPropType),
  selectedExtras: PropTypes.arrayOf(PropTypes.string),
  updateStage: PropTypes.func,
  selectExtra: PropTypes.func
}

export default ExtrasSelect
