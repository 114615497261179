import { createSlice } from '@reduxjs/toolkit'
import { call, put, takeLatest } from 'redux-saga/effects'
import API from '../api'

export const stages = {
  INITIAL_INPUT: 'INITIAL_INPUT',
  ADDRESS_SELECT: 'ADDRESS_SELECT',
  PLAN_SELECT: 'PLAN_SELECT',
  EXTRAS_SELECT: 'EXTRAS_SELECT',
  PLAN_PURCHASE: 'PLAN_PURCHASE'
}

const { actions, reducer } = createSlice({
  name: 'availability',
  initialState: {
    error: false,
    noDeals: false,
    loading: false,
    addressResults: [],
    stage: stages.INITIAL_INPUT,
    availablePlans: []
  },
  reducers: {
    REQUEST_ADDRESSES (state, { payload }) {
      state.loading = true
    },
    REQUEST_ADDRESSES_SUCCESS (state, { payload }) {
      state.loading = false
      state.addressResults = payload
    },
    REQUEST_PLANS (state, { payload }) {
      state.loading = true
    },
    REQUEST_PLANS_SUCCESS (state, { payload }) {
      state.loading = false
      state.availablePlans = payload.sort((a, b) => a.maxDownload - b.maxDownload)
    },
    REQUEST_FAILED (state, { payload }) {
      state.error = true
      state.loading = false
    },
    NO_DEALS_FOUND (state, { payload }) {
      state.loading = false
      state.noDeals = true
    },
    UPDATE_STAGE (state, { payload }) {
      if (stages[payload]) {
        state.stage = stages[payload]
      }
    }
  }
})

function * onRequestAddresses ({ payload }) {
  try {
    const { data } = yield call(
      API.get, '/availability/addresses', {
        params: {
          postcode: payload.postcode
        }
      }
    )
    yield put(actions.REQUEST_ADDRESSES_SUCCESS(data))
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestPlans ({ payload }) {
  try {
    const params = {}
    if (payload.tel) {
      params.tel = payload.tel
    }
    if (payload.address) {
      params.address = payload.address
    }
    const { data } = yield call(
      API.get, '/availability/plans', { params }
    )
    if (data.success) {
      yield put(actions.REQUEST_PLANS_SUCCESS(data.plans))
    } else {
      yield put(actions.NO_DEALS_FOUND())
    }
  } catch (err) {
    yield put(actions.REQUEST_FAILED({ message: err.message }))
  }
}

function * onRequestFailed ({ payload }) {
  yield call(console.error, payload)
}

export function * saga () {
  yield takeLatest('availability/REQUEST_ADDRESSES', onRequestAddresses)
  yield takeLatest('availability/REQUEST_PLANS', onRequestPlans)
  yield takeLatest('availability/REQUEST_FAILED', onRequestFailed)
}

export const { REQUEST_ADDRESSES, REQUEST_PLANS, UPDATE_STAGE } = actions

export default reducer
