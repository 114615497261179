import { connect } from 'react-redux'
import { REQUEST_PAYMENTS } from '../ducks/customer'
import PaymentHistory from '../components/PaymentHistory'

const mapStateToProps = state => {
  return {
    loading: state.customer.loadingPaymentHistory,
    payments: state.customer.selectedSubscription && state.customer.paymentsBySubscription[state.customer.selectedSubscription]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPayments: ({ userID, subscriptionID }) => dispatch(REQUEST_PAYMENTS({ userID, subscriptionID }))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory)
