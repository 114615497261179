import { connect } from 'react-redux'

import { REQUEST_PLANS, UPDATE_STAGE } from '../ducks/availability'
import { SELECT_PLAN } from '../ducks/customer'
import PlanSelect from '../components/PlanSelect'

const mapStateToProps = state => {
  return {
    loading: state.availability.loading,
    availablePlans: state.availability.availablePlans,
    noDeals: state.availability.noDeals,
    tel: state.customer.tel,
    address: state.customer.address
  }
}

const mapDispatchToProps = dispatch => {
  return {
    requestPlans: query => dispatch(REQUEST_PLANS(query)),
    updateStage: stage => dispatch(UPDATE_STAGE(stage)),
    selectPlan: plan => dispatch(SELECT_PLAN(plan))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelect)
