import React from 'react'
import * as icons from 'react-icons/io'
import PropTypes from 'prop-types'

import { planPropType } from '../../types'
import Button from '../Button'
import styles from './PlanSelect.module.scss'

const formatSpeed = speed => speed >= 1000 ? (speed / 1000) + 'gb/s' : speed + 'mb/s'

const PlanCard = ({ plan, onSelect }) => (
  <div className={styles.planCard} role="document">
    <h4 className={styles.planHeading}>{plan.name}</h4>
    <div className={styles.planSpeeds}>
      <div className={styles.planSpeed}><icons.IoMdArrowDown /> <span className={styles.speedPrefix}>up to</span> {formatSpeed(plan.estimatedDownload)} <span className={styles.speedSuffix}>download</span></div>
      <div className={styles.planSpeed}><icons.IoMdArrowUp /> <span className={styles.speedPrefix}>up to</span> {formatSpeed(plan.estimatedUpload)} <span className={styles.speedSuffixdownload}>upload</span></div>
    </div>
    <div className={styles.planInner}>
      <div className={styles.planPricing}>
        £{plan.monthlyCost / 100}/month
      </div>
      <ul className={styles.planFeatures}>
        {plan.features.map(feature => {
          const Icon = icons[feature.icon]
          return (
            <li key={feature._id} className={styles.planFeature}><Icon /> {feature.description}</li>
          )
        })}
      </ul>
      {plan.websiteLink && (
        <a className={styles.externalLink} href={plan.websiteLink} target="_blank" rel="noreferrer noopener">more info</a>

      )}
      {plan.notes.map((note, i) => <p key={i} className={styles.planNote}>{note}</p>)}
    </div>
    <Button onClick={onSelect}>Select this Package</Button>
  </div>
)

PlanCard.propTypes = {
  plan: planPropType,
  onSelect: PropTypes.func
}

export default PlanCard
