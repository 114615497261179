import { connect } from 'react-redux'

import { UPDATE_STAGE } from '../ducks/availability'
import { SELECT_EXTRA } from '../ducks/customer'
import ExtrasSelect from '../components/ExtrasSelect'

const mapStateToProps = state => {
  return {
    extras: state.customer.selectedPlan.extras,
    selectedExtras: state.customer.selectedExtras.map(n => n._id)
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateStage: stage => dispatch(UPDATE_STAGE(stage)),
    selectExtra: (extra) => dispatch(SELECT_EXTRA(extra))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExtrasSelect)
