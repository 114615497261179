import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import styles from './MyDetails.module.scss'
import Form from '../Form'
import Field from '../Form/Field'
import Input from '../Input'
import Button from '../Button'

const MyDetails = ({ requestMe, updateMe, me, loading, submitting }) => {
  const [formHasChanges, setFormChanges] = useState(false)
  const formRef = useRef()
  const handleSubmit = ({ values, valid }) => {
    if (valid) {
      updateMe({
        _id: me._id,
        address: {
          line1: values['address.line1'],
          line2: values['address.line2'],
          line3: values['address.line3'],
          city: values['address.city'],
          region: values['address.region'],
          postcode: values['address.postcode']
        },
        phone: values.phone
      })
      setFormChanges(false)
    }
  }
  const handleKeyUp = e => {
    setFormChanges(true)
  }
  useEffect(() => {
    requestMe()
  }, [requestMe])
  if (loading) return ''
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img src={me.companyName ? 'company_avatar.png' : '/avatar.png'} alt="generic avatar" className={styles.avatar}/>
        <h1 className={styles.name}>{me.firstName} {me.lastName}</h1>
        <p className={styles.customerSince}>Customer since {moment(me.createdAt).format('MMMM YYYY')}</p>
      </div>
      <h2 className={styles.heading}>Contact Information</h2>
      <Form ref={formRef} onSubmit={handleSubmit} className={styles.details}>
        <Field validations={['isEmail']} label="Email Address">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="email"
            autoComplete="email-address"
            defaultValue={me.email}
            name="email" />
        </Field>
        <Field required label="Address Line 1">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.address && me.address.line1}
            name="address.line1" />
        </Field>
        <Field label="Address Line 2">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.address && me.address.line2}
            name="address.line2" />
        </Field>
        <Field label="Address Line 3">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.address && me.address.line3}
            name="address.line3" />
        </Field>
        <Field required label="Town/City">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.address && me.address.city}
            name="address.city" />
        </Field>
        <Field label="County">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.address && me.address.region}
            name="address.region" />
        </Field>
        <Field required label="Postcode">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.address && me.address.postcode}
            name="address.postcode" />
        </Field>
        <Field validations={['isNumeric']} label="Telephone">
          <Input
            disabled={submitting}
            style={{ width: '100%' }}
            onKeyUp={handleKeyUp}
            type="text"
            defaultValue={me.phone}
            name="phone" />
        </Field>
        {formHasChanges && <Button type="submit" loading={submitting} disabled={!formHasChanges} >
          {loading ? 'Saving...' : 'Save Changes'}
        </Button>}
      </Form>
    </div>
  )
}

MyDetails.propTypes = {
  // me: PropTypes.shape({ }),
  me: PropTypes.object,
  requestMe: PropTypes.func,
  updateMe: PropTypes.func,
  submitting: PropTypes.bool,
  loading: PropTypes.bool
}

export default MyDetails
